<template>
    <div class="page">
        <div class="list">
            <div class="li" v-for="item in list" :key="item.id">
                <div class="logo"><img :src="item.logo" alt=""></div>
                <div class="info">
                    <div class="name">{{ item.shortName }}</div>
                    <div class="desc" v-if="item.balance">余额: ¥{{ regFenToYuan(item.balance)}}</div>
                </div>
                <template v-if="item.code == 'MEITUAN_M'">
                    <div class="tips">
                        <span>自动绑定，只接美团订单</span>
                    </div>
                </template>
                <template v-else>
                    <div class="tips" v-if="item.authStatus == 1">
                        <span>已绑定</span>
                    </div>
                    <div class="status" v-if="item.authStatus == 0" @click="onBind(item)">
                        <span>去绑定</span>
                    </div>
                </template>
            </div>
        </div>

    </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from "vue";
import { http } from "@/http";
import { Toast } from "vant";
import { useRoute, useRouter } from "vue-router";
import { regFenToYuan } from '@/utils/index';

const $route = useRoute();
const $router = useRouter();

const query = $route.query || {};

const list = ref([]);


onMounted(() => {
    login();
});

const login = () => {
    let params = {
        loginType: 'H5',
        sideType: 'H5',
        shopId: query.shopId
    };
    http.post('/?cmd=com.xqxc.api.pigear.biz.account.UserLoginService.userLogin&group=earApp', params).then(res => {
        let _auth = res.result;
        window.localStorage.setItem('phkjPigearAuth', JSON.stringify(_auth));
        getList();
    }).catch(err => {
        Toast(err.errorName);
    });
};

const getList = () => {
    let params = {
        shopId: query.pigearShopId,
        type: 'SMALL'
    };
    http.post("/?cmd=com.xqxc.api.pigear.biz.platform.TransportCompanyService.listTransportCompanyInfoV3&group=earApp", params).then((res) => {
        list.value = res.result.SMALL || [];
    }).catch((err) => {
        console.log(err, "err");
    });
};

const onBind = (item)=>{

    if (item.jumpUrl){
        window.location.href = item.jumpUrl;
        return false;
    }
    let params = {
        code: item.code,
        shopId: query.pigearShopId
    };
    http.post('/?cmd=com.xqxc.api.pigear.biz.platform.TransportCompanyService.getTransportAuthUrl&group=earApp', params).then(res=>{
        window.location.href = res.result.url;
    }).catch(err=>{
        console.log(err);
    });
};

</script>

<style lang="scss" scoped>
.page {
    background: #f3f7fa;
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .list {
        background-color: #FFF;
        padding: 0 .3rem;

        .li {
            height: 1.4rem;
            border-bottom: 0.01rem solid #eee;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:last-child {
                border-bottom: none;
            }

            .logo {
                width: 0.8rem;
                height: 0.8rem;
                border-radius: 50%;
                overflow: hidden;

                image {
                    width: 0.8rem;
                    height: 0.8rem;
                }
            }

            .info {
                flex: 1;
                padding: 0 0.2rem;
                line-height: 1.5;

                .name {
                    font-size: 0.34rem;
                    color: #000;
                }

                .desc {
                    font-size: 0.28rem;
                    color: #999;
                }
            }

            .status {
                display: flex;
                align-items: center;
                color: #56ADFF;
                background: url('../../assets/images/icon.arrow.right.png') no-repeat right center;
                background-size: 0.11rem 0.22rem;
                padding-right: 0.2rem;
            }
            .tips{
                display: flex;
                align-items: center;
                color: #999;
                background: url('../../assets/images/icon.arrow.right.png') no-repeat right center;
                background-size: 0.11rem 0.22rem;
                padding-right: 0.2rem;
            }
        }
    }
}
</style>